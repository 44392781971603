@tailwind base;
@tailwind components;
@tailwind utilities;
@layer mantine, mantine-datatable;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */

.glass {
  background: radial-gradient(
    80.6% 40.3% at 100% 36%,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.05) 100%
  ) !important;
  backdrop-filter: blur(8px) !important;
}

.glassNav {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1),
    rgba(53, 53, 53, 0.1)
  );
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.bg-main {
}

.border-custom {
  border: 1px solid rgba(255, 255, 255, 0);
}

html,
body {
  height: 100%;
  min-height: 90vh;
  background-image: radial-gradient(
    circle,
    #16394f,
    #153148,
    #142a40,
    #132238,
    #111b30,
    #111930,
    #12172f,
    #13152e,
    #171735,
    #1c183b,
    #211942,
    #281a48
  );
}

#__next {
  height: 100%;
  gap: 2px;
  background-image: radial-gradient(
    circle,
    #16394f,
    #153148,
    #142a40,
    #132238,
    #111b30,
    #111930,
    #12172f,
    #13152e,
    #171735,
    #1c183b,
    #211942,
    #281a48
  );
  z-index: 0;
  backdrop-filter: blur(25px);
  position: relative;
}

@media (max-width: 640px) {
  #__next {
    height: auto;
  }
}

@media (max-width: 900px) and (orientation: landscape) {
  html,
  body {
    height: auto;
    min-height: 90vh;
  }

  #__next {
    height: auto;
  }
}

@media (min-width: 640px) {
  #__next {
    height: 100%;
    display: flex;
  }
}

.bg-gradient {
  background-image: linear-gradient(
    to right,
    #8bdeda,
    #43add0,
    #998ee0,
    #e17dc2,
    #ef9393
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Navbar & Animation */
.navBarMobile__bg {
  background-image: radial-gradient(
    circle,
    rgba(22, 57, 79, 0.9),
    rgba(21, 49, 72, 0.9),
    rgba(20, 42, 64, 0.9),
    rgba(19, 34, 56, 0.9),
    rgba(17, 27, 48, 0.9),
    rgba(17, 25, 48, 0.9),
    rgba(18, 23, 47, 0.9),
    rgba(19, 21, 46, 0.9),
    rgba(23, 23, 53, 0.9),
    rgba(28, 24, 59, 0.9),
    rgba(33, 25, 66, 0.9),
    rgba(40, 26, 72, 0.9)
  );
}

.navOpen {
  animation: changeWidthOpen 1s forwards;
}

.navClose {
  animation: changeWidthClose 1s forwards;
}

@keyframes changeWidthClose {
  from {
    width: 250px;
  }

  to {
    width: 3rem;
  }
}

@keyframes changeWidthOpen {
  from {
    width: 3rem;
  }

  to {
    width: 250px;
  }
}

/* Landing Page */
.rotating-text {
  display: inline-block;
}

.rotating-text::after {
  content: "|";
  animation: blinking-cursor 1s infinite;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Néomorf tools */
.neomorphCirlcle {
  border-radius: 150px;
  background: #ffffff;
  box-shadow:
    5px 5px 10px #d9d9d9,
    -5px -5px 10px #ffffff;
}

/* Response Markdown */
.markdown h1 {
  font-size: x-large;
  font-weight: 700;
}

.markdown h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: large;
  font-weight: 700;
}

.markdown h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: medium;
  font-weight: 600;
}

.markdown li {
  list-style: disc;
  padding: 8px;
}

.markdown p {
  white-space: pre-wrap;
}

/* Button IA */

.buttonia-wrapper {
  position: relative;
}

.buttonia {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  letter-spacing: 2px;
  padding: 6px 16px;
  background-color: white;
  color: rgb(18 20 50);
  border-radius: 8px;
  border: 0px;
  cursor: pointer;
  z-index: 100;
  position: relative;
}

.buttonia-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: linear-gradient(
    90deg,
    #8bdeda,
    #43add0,
    #998ee0,
    #e17dc2,
    #ef9393
  );
  background-size: 600% 600%;
  border-radius: 10px;
  animation: AnimateBorder 4s ease infinite;
  -webkit-animation: AnimateBorder 4s ease infinite;
  -moz-animation: AnimateBorder 4s ease infinite;
  z-index: 0;
  transform: translate(-2px, -2px);
  transition: filter 0.2s ease-in;
}

.buttonia-wrapper:hover .buttonia-bg {
  filter: blur(10px);
  transition: filter 0.4s ease-in;
}

/* Editoria */
.fake-caret {
  display: inline-block;
  width: 2px;
  /* background-color: #E17DC2; */
  opacity: 70%;
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    background-color: transparent;
  }
}

.editorClassName {
  min-height: 500px;
}

.ql-clipboard {
  position: fixed !important;
  display: none;
  left: 50%;
  top: 50%;
}

.container .ql-editor {
  width: 100%;
  min-height: 500px;
  padding: 30px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
  padding-top: 3rem;
  padding-bottom: 240px;
  flex-grow: 1;
  padding-left: max(calc((100% - 700px) / 2), 64px);
  padding-right: max(calc((100% - 700px) / 2), 64px);
}

/* @media screen and (min-width: 640px) {
    .container .ql-editor {
        padding: 70px;
    }
} */

.ql-editor {
  /* overflow-y: initial !important; */
  overflow-x: hidden;
}

.page-break {
  position: relative;
  height: 70px;
  width: 100%;
  margin: 73px 0 73px 0;
}

.container {
  height: auto;
  padding: 0;
  overflow: auto;
}

.container .ql-container.ql-snow {
  border: none;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #121432;
  font-family: "Montserrat", sans-serif;
}

.ql-size-extra-large,
.ql-picker-item[data-value="extra-large"]::before {
  line-height: 45px;
  font-size: 2.375rem !important;
}

.ql-size-large,
.ql-picker-item[data-value="large"]::before {
  line-height: 30px;
  font-size: 1.3125rem !important;
}

.ql-size-extra-small,
.ql-picker-item[data-value="extra-small"]::before {
  font-size: 0.6rem !important;
}

.container .ql-toolbar.ql-snow {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  border: 1px solid #cbd5e1;
}

/* .quill {
    width: 794px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

@media screen and (min-width: 640px) {
    .quill {
        align-items: center;
    }
}


.ql-container {
    width: 100%;
    background-color: white;
    margin-top: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    word-wrap: break-word;
    max-height: 1123px;
    border: 1px solid #a9b6c2;
    /* équivalent approximatif pour "slate-200" 
}

@media screen and (min-width: 640px) {
    .ql-editor {
        height: 1123px;
    }
}*/

.rdw-right-aligned-block > div,
.rdw-left-aligned-block > div,
.rdw-center-aligned-block > div,
.rdw-justify-aligned-block > div {
  max-width: 774px;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.public-DraftStyleDefault-ltr {
  /*padding-bottom: 50px;*/
  text-align: inherit !important;
}

.public-DraftStyleDefault-ltr > span > span {
  transform: scale(0.6);
}

.ql-editor ::selection {
  /* background: rgba(225, 125, 194, 0.9); */
  /* Couleur de fond de la sélection */
  /* color: white; */
  /* Couleur du texte de la sélection */
  background: #d5d8fe;
  /* background: rgb(222 221 253/var(--tw-bg-opacity)); */
}

/* pour la compatibilité avec les navigateurs WebKit */
.ql-editor ::-webkit-selection {
  background: #d5d8fe;
  /* background: rgba(59, 130, 246, .5); */
  /* background: rgba(225, 125, 194, 0.9); */
  /* color: white; */
}

/*Scroll Bar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  max-height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Loader */
.loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

/*LOADER-17*/

.loader-17 .css-square {
  position: absolute;
  top: 50%;
  width: 25px;
  height: 7px;
  background: white;
  -webkit-box-shadow: 2px 2px 3px 0px black;
  box-shadow: 2px 2px 3px 0px black;
}

.loader-17 .square1 {
  background-color: #8bdeda;
  left: 70px;
  -webkit-animation: dominos 1s 0.125s ease infinite;
  animation: dominos 1s 0.125s ease infinite;
}

.loader-17 .square2 {
  background-color: #43add0;
  left: 60px;
  -webkit-animation: dominos 1s 0.3s ease infinite;
  animation: dominos 1s 0.3s ease infinite;
}

.loader-17 .square3 {
  background-color: #998ee0;
  left: 50px;
  -webkit-animation: dominos 1s 0.425s ease infinite;
  animation: dominos 1s 0.425s ease infinite;
}

.loader-17 .square4 {
  background-color: #e17dc2;
  left: 40px;
  -webkit-animation: dominos 1s 0.54s ease infinite;
  animation: dominos 1s 0.54s ease infinite;
}

.loader-17 .square5 {
  background-color: #ef9393;
  left: 30px;
  -webkit-animation: dominos 1s 0.665s ease infinite;
  animation: dominos 1s 0.665s ease infinite;
}

.loader-17 .square6 {
  left: 20px;
  -webkit-animation: dominos 1s 0.79s ease infinite;
  animation: dominos 1s 0.79s ease infinite;
}

.loader-17 .square7 {
  left: 10px;
  -webkit-animation: dominos 1s 0.9s ease infinite;
  animation: dominos 1s 0.9s ease infinite;
}

.loader-17 .square8 {
  left: 0px;
  -webkit-animation: dominos 1s 1s ease infinite;
  animation: dominos 1s 1s ease infinite;
}

@-webkit-keyframes dominos {
  50% {
    opacity: 0.7;
  }

  75% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  80% {
    opacity: 1;
  }
}

@keyframes dominos {
  50% {
    opacity: 0.7;
  }

  75% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  80% {
    opacity: 1;
  }
}

@-webkit-keyframes AnimateBorder {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes AnimateBorder {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBorder {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes animationGradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 100% 0%;
  }
}

@keyframes animationGradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 50% 100%;
  }

  100% {
    background-position: 100% 0%;
  }
}

/* Chat IA messages */
.message {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-radius: 1.125rem 1.125rem 1.125rem 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  width: 94%;
  /* box-shadow: 0 0 2rem rgba(0, 0, 0, 0.075), 0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.1); */
}

.assistant {
  display: flex;
  justify-content: flex-start;
  color: black;
  margin: 0.5rem auto 0.5rem 0.5rem;
  padding: 12px 30px;
  border: 1px solid rgb(148 163 184);
}

.user {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0.5rem 0.5rem auto;
  border-radius: 1.125rem 1.125rem 0 1.125rem;
  background-color: #5865fc;
  color: white;
  width: 94%;
  padding: 12px 30px;
}

.ReactSyntaxHighlighter code {
  display: flex;
  flex-direction: column;
  width: max-content;
}

code {
  overflow: auto;
  background-color: rgb(247, 247, 247);
  color: rgb(47, 47, 47);
  border-radius: 8px;
  line-height: 2;
  overflow-x: auto;
  word-spacing: normal;
  word-break: normal;
  overflow-wrap: break-word;
}

pre {
  overflow-x: auto;
}

/* Chatia scrollBar Hide */
/* Hide scrollbar for Chrome, Safari and Opera */
.ShowScrollBaronHover::-webkit-scrollbar {
  display: none;
  overflow-y: auto;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ShowScrollBaronHover {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scroll-container:hover::-webkit-scrollbar {
  width: 8px;
  display: none;
}

@layer base {
  .dark {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 10.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  :root {
    --background: 0, 0%, 31%, 40%;
    --foreground: 213 31% 99%;

    --muted: 0, 0%, 24%;
    --muted-foreground: 0, 0 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 0 0% 95%, 20%;
    --card-foreground: 250 31% 100%;

    --border: 0 0% 40%;
    --input: 240 96% 8%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 240 96% 78%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 240 96% 88%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}

/* Globale animation */
.fade-in-animation {
  -webkit-animation: fade-in 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-out-animation {
  -webkit-animation: fade-out 0.5s ease-out both;
  animation: fade-out 0.5s ease-out both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

/* speaking Monster */
.monster {
  display: flex;
  justify-content: center;
  position: relative;
  width: 370px;
  height: 490px;
  border-radius: 100% / 125% 125% 80% 80%;
  background-image: linear-gradient(
    to bottom,
    #8bdeda,
    #43add0,
    #998ee0,
    #e17dc2,
    #ef9393
  );
  box-shadow: 80px 80px 0 #eeeeee;
}

.monster__face {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 19%;
  width: 75%;
  height: 200px;
}

.monster__eyes {
  display: flex;
  justify-content: space-between;
  width: 50%;
  height: auto;
  margin-bottom: 10px;
}

.monster__eye {
  width: 60px;
  height: 100px;
  border-radius: 100% / 125% 125% 80% 80%;
  background: #ffffff; /* Noir */
}

.monster__mouth {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 0%;
  overflow: hidden;
  border: 25px solid #e17dc2; /* Jaune */
  border-radius: 100px;
  background-color: #ff6347; /* Rouge tomate */
}

.monster__mouth.active {
  animation: mouth 1.75s infinite;
}

.monster__mouth::before {
  content: "";
  position: absolute;
  width: 150px;
  height: 80px;
  border-radius: 100px;
  background-color: #ff4500; /* Rouge orangé */
}

.monster__mouth::after {
  content: "";
  position: absolute;
  bottom: -80px;
  width: 160px;
  height: 80px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background-color: #ffc0cb; /* Rose */
}

.monster__mouth.active::after {
  animation: tongue 1.75s infinite;
}

.monster__top {
  position: absolute;
  top: -30px;
  width: 170px;
  height: 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ffffff; /* Blanc */
  z-index: 100;
}

.monster__top.active {
  animation: t 1.75s infinite;
}

.monster__bottom {
  position: absolute;
  bottom: 0;
  width: 100px;
  height: 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #ffffff; /* Blanc */
  z-index: 100;
}

.monster__bottom.active {
  animation: b 1.75s infinite;
}

/* Keyframes pour les animations */
@keyframes t {
  0%,
  10%,
  80%,
  100% {
    top: -30px;
  }
  20% {
    top: 0px;
  }
  30% {
    top: -20px;
  }
  40% {
    top: 0px;
  }
  50% {
    top: -25px;
  }
  70% {
    top: 0px;
  }
}

@keyframes b {
  0%,
  10%,
  80%,
  100% {
    bottom: -30px;
  }
  20% {
    bottom: 0px;
  }
  30% {
    bottom: -20px;
  }
  40% {
    bottom: 0px;
  }
  50% {
    bottom: -25px;
  }
  70% {
    bottom: 0px;
  }
}

@keyframes mouth {
  0%,
  10%,
  100% {
    width: 100%;
    height: 0%;
  }
  15% {
    width: 90%;
    height: 30%;
  }
  20% {
    width: 50%;
    height: 70%;
  }
  25% {
    width: 70%;
    height: 70%;
  }
  30% {
    width: 80%;
    height: 60%;
  }
  35% {
    width: 60%;
    height: 70%;
  }
  40% {
    width: 55%;
    height: 75%;
  }
  45% {
    width: 50%;
    height: 90%;
  }
  50% {
    width: 40%;
    height: 70%;
  }
  55% {
    width: 70%;
    height: 95%;
  }
  60% {
    width: 40%;
    height: 50%;
  }
  65% {
    width: 100%;
    height: 60%;
  }
  70% {
    width: 100%;
    height: 70%;
  }
  75% {
    width: 90%;
    height: 70%;
  }
  80% {
    width: 50%;
    height: 70%;
  }
  85% {
    width: 90%;
    height: 50%;
  }
  85% {
    width: 40%;
    height: 70%;
  }
  90% {
    width: 90%;
    height: 30%;
  }
  95% {
    width: 100%;
    height: 10%;
  }
}

@keyframes tongue {
  0%,
  20%,
  100% {
    bottom: -80px;
  }
  30%,
  90% {
    bottom: -40px;
  }
  40% {
    bottom: -45px;
  }
  50% {
    bottom: -50px;
  }
  70% {
    bottom: -80px;
  }
  90% {
    bottom: -40px;
  }
}

/* Record button*/
.containerMonster {
  position: absolute;
  left: 50%;
  top: 20%;
  width: 170px;
  height: 170px;
  margin: -85px -85px;
}
.containerMonster #btn {
  display: none;

  width: 60px;
  height: 60px;
  position: relative;
  left: 50px;
  /* top: 55px; */
}

.containerMonster #btn + label:before {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 130px;
  height: 130px;
  margin: -65px -65px;
  content: "";
  -webkit-transform: translate(-6px, -6px);
  -ms-transform: translate(-6px, -6px);
  transform: translate(-6px, -6px);
  border-radius: 50%;
  /*border: 6px solid #000;*/
  cursor: pointer;
}
.containerMonster #btn + label:after {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -50px -50px;
  content: "";
  border-radius: 50px;
  background: #ff6c77;
  cursor: pointer;
}
.containerMonster #btn:checked + label:after {
  -webkit-animation: stop 0.5s infinite cubic-bezier(0.4, -0.9, 0.9, 1);
  -moz-animation: stop 0.5s infinite cubic-bezier(0.4, -0.9, 0.9, 1);
  -o-animation: stop 0.5s infinite cubic-bezier(0.4, -0.9, 0.9, 1);
  animation: stop 0.5s infinite cubic-bezier(0.4, -0.9, 0.9, 1);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes stop {
  70% {
    border-radius: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 60px;
    margin: -30px -30px;
  }
  100% {
    border-radius: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 64px;
    height: 64px;
    margin: -32px -32px;
  }
}

#timer {
  position: relative;
  top: 30%;
  bottom: 0;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  opacity: 0;
}

.containerMonster #btn:checked ~ label ~ .tim {
  opacity: 1;
}
